<template>
  <base-material-card
    icon="fa fa-newspaper"
    :title="`${lname}: Aktuelles`"
    color="primary"
  >
    <v-card
      flat
      v-for="n in news"
      :key="n._id"
      class="grey lighten-3"
      @click="dialog = n"
    >
      <v-card-text class="pt-4">
        <v-icon
          v-if="n.pinned"
          style="float:right;font-size:100%;"
        >far fa-thumbtack</v-icon>
        <h4 class="mb-4">{{ n.dateCreated | dateformat }} ({{ n.author | person }})</h4>
        <div>{{ n.short }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text>Mehr...</v-btn>
      </v-card-actions>
    </v-card>
    <base-material-dialog
      v-if="dialog !== null"
      :value="dialog !== null"
      icon="far fa-newspaper"
      title="Aktuelles"
      :sub-title="`${$options.filters.dateformat(dialog.dateCreated)} (${$options.filters.person(dialog.author)})`"
      color="primary"
      :actions="[ 'close' ]"
      @close="dialog = null"
      @esc="dialog = null"
    >
      <v-card flat v-html="dialog.text" />
      <v-card flat v-if="dialog.attachments.length > 0" style="clear:both;">
        <h2>Anhänge:</h2>
        <ul>
          <li v-for="a in dialog.attachments" :key="a._id">
            <a :href="`${imageBase}${a._id}`" :download="a.filename" target="_blank">{{ a.filename }}</a>
            ({{ a.mimetype }})
          </li>
        </ul>
      </v-card>
    </base-material-dialog>
  </base-material-card>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import { GRAPHQLserver } from '@/env'

const query = `
  _id published pinned dateCreated text
  author { _id givenName familyName }
  attachments { _id filename mimetype }
`

export default {
  name: 'News',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: {},
    NewsFind: [],
    dialog: null
  }),

  computed: {
    lname () {
      return this.Event.name || ''
    },
    imageBase () {
      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      const url = document.URL.match(/[^/]*\.de/)

      const address = base[2].match('stb.de') ? url : base[2]

      return `http${base[1] === 'wss' ? 's' : ''}://${address}/uploads/`
    },
    news () {
      return this.NewsFind
        .filter(n => n.published)
        .sort((a, b) => {
          if (a.pinned && !b.pinned) return -1
          if (!a.pinned && b.pinned) return 1
          return a.dateCreated > b.dateCreated ? -1 : 1
        })
        .map(n => {
          const text = n.text.replaceAll('<img src="/', `<img src="${this.imageBase}`)
          const short = text.replaceAll(/<[^>]*>/g, ' ')
          return {
            ...n,
            text,
            short: short.length > 100 ? `${short.substring(0, 100)}...` : short
          }
        })
    }
  },

  methods: {
  },

  apollo: {
    Event: {
      query: gql` query($id: UUID!) {
        Event(id: $id) {
          _id
          ... on StbW2023 { name }
        }}`,
      variables () {
        return {
          id: this.id
        }
      }
    },
    NewsFind: {
      query: gql`query($subjectOf: UUID!) {
        NewsFind(subjectOf: $subjectOf) { ${query} }
      }`,
      variables () {
        return {
          subjectOf: this.id
        }
      },
      subscribeToMore: {
        document: gql`subscription($subjectOf: UUID!) {
           NewsCreate(subjectOf: $subjectOf) { ${query} }
        }`,
        variables () {
          return {
            subjectOf: this.id
          }
        },
        updateQuery: updateQuery('NewsFind', 'NewsCreate')
      }
    },
    $subscribe: {
      NewsUpdate: {
        query: gql`subscription($subjectOf: UUID!) {
          NewsUpdate(subjectOf: $subjectOf) { ${query} }
        }`,
        variables () {
          return {
            subjectOf: this.id
          }
        }
      },
      NewsDelete: {
        query: gql`
          subscription { NewsDelete }
        `,
        result (id) {
          deleteQuery('NewsFind', 'NewsDelete', this.NewsFind, id)
        }
      }
    }
  }
}
</script>
